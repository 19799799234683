import get from 'lodash/get';

import { Chaport } from 'core/entities';
import { APIService } from 'core/services';

export class ChaportService extends APIService {
  public async saveChaportVisitorId(
    chaportId: number | string,
    cookies?: Optional<string>
  ): Promise<{ status: Optional<number> }> {
    const response = await this.execute('/api/v3/user/chaport_id/', 'POST', {
      payload: { chaportId },
      customHeaders: {
        Cookie: cookies
      },
      withCredentials: true
    });

    return {
      status: get(response, 'status', null)
    };
  }

  public async getChaportVisitorId(): Promise<Chaport> {
    const response = await this.execute('/api/v3/user/chaport_id/', 'GET', {
      withCredentials: true
    });

    return { chaportId: get(response, 'data.chaportId', ''), visitorHash: get(response, 'data.visitorHash', '') };
  }
}
