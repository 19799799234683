import * as React from 'react';

import { Chaport } from 'core/entities';
import { ChaportService } from 'core/services/chaport';

declare global {
  interface Window {
    chaport: {
      q: (value: string) => null;
      on: (
        eventName: string,
        callback: (e: { visitorId: number | string; fromState: string; toState: string }) => void
      ) => void;
    };
  }
}

export const useChaport = (
  host: string,
  user: Optional<{
    id: number;
    isImpersonated?: boolean;
  }>,
  cookies?: Optional<string>,
  chaportUserData?: Chaport
) => {
  const chaportService = new ChaportService(host);

  const chaportListener = (e: { fromState: string; toState: string }) => {
    if (
      e &&
      e.fromState === 'expanded' &&
      e.toState === 'collapsed' &&
      typeof window !== 'undefined' &&
      window.chaport
    ) {
      window.chaport.q('hideLauncher');
    }
  };

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.chaport) {
      window.chaport.on('widget.stateChange', chaportListener);
    }
  }, []);

  React.useEffect(() => {
    if (typeof window !== 'undefined' && window.chaport) {
      window.chaport.on('ready', (data) => {
        if (!chaportUserData?.chaportId) {
          if (chaportService && data.visitorId && data.visitorId !== 'undefined' && user && user.id) {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            chaportService.saveChaportVisitorId(data.visitorId, cookies).then().catch();
          }
        }
        return;
      });
    }
  }, [chaportUserData, user]);

  const openChaport = () => {
    if (typeof window !== 'undefined' && window.chaport) {
      window.chaport.q('showLauncher');
      window.chaport.q('open');
      return false;
    }
    return false;
  };

  return { openChaport };
};
